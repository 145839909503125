// src/Modal.js
import React from "react";
import "./Modal.css";

const Modal = ({ isOpen, onClose, score, image, scoreText }) => {
  if (!isOpen) return null;

  console.log(image);
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{scoreText}</h2>
        <p>Your Score: {score}</p>
        <img
          src={"/" + image + ".jpg"}
          alt="Game Over"
          width="250"
          height="250"
        />
        <button onClick={onClose}>Play Again</button>
      </div>
    </div>
  );
};

export default Modal;
