import React, { useState, useEffect } from "react";
import "./App.css";
import Modal from "./Modal"; // Import the Modal component

const initialSnake = [[0, 0]];
const initialFood = [5, 5];
const boardSize = 20;

const App = () => {
  const [snake, setSnake] = useState(initialSnake);
  const [food, setFood] = useState(initialFood);
  const [direction, setDirection] = useState("RIGHT");
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0); // Add score state
  const [modalOpen, setModalOpen] = useState(false); // Modal state

  // Define image and text configurations based on score
  const scoreImageConfig = {
    1: "/logo512.png", // Image for scores 10 and below
    2: "/logo192.png", // Image for scores 11 to 20
    3: "/logo192.png", // Image for scores 21 to 30
    // Add more configurations as needed
  };
  const scoreTextConfig = {
    1: "Your snake is too small but I like it.",
    2: "Excellent! You have a large snake!",
    3: "Incredible! Damn thats soo big honey!",
    // Add more configurations as needed
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowUp":
          setDirection("UP");
          break;
        case "ArrowDown":
          setDirection("DOWN");
          break;
        case "ArrowLeft":
          setDirection("LEFT");
          break;
        case "ArrowRight":
          setDirection("RIGHT");
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      if (!gameOver) {
        moveSnake();
      }
    }, 200);

    return () => clearInterval(gameLoop);
  }, [snake, direction, gameOver]);

  const moveSnake = () => {
    const newSnake = [...snake];
    const head = newSnake[0].slice();

    switch (direction) {
      case "UP":
        head[0]--;
        break;
      case "DOWN":
        head[0]++;
        break;
      case "LEFT":
        head[1]--;
        break;
      case "RIGHT":
        head[1]++;
        break;
      default:
        break;
    }

    newSnake.unshift(head);

    // Check for food collision
    if (head[0] === food[0] && head[1] === food[1]) {
      setFood(generateFood());
      setScore(score + 1); // Increment score
    } else {
      newSnake.pop(); // Remove tail
    }

    // Check for collisions
    if (isGameOver(head, newSnake)) {
      setGameOver(true);
      setModalOpen(true); // Open modal
    }

    setSnake(newSnake);
  };

  const generateFood = () => {
    let newFood;
    while (true) {
      newFood = [
        Math.floor(Math.random() * boardSize),
        Math.floor(Math.random() * boardSize),
      ];
      if (
        !snake.some(
          (segment) => segment[0] === newFood[0] && segment[1] === newFood[1]
        )
      ) {
        break;
      }
    }
    return newFood;
  };

  const isGameOver = (head, snake) => {
    return (
      head[0] < 0 ||
      head[0] >= boardSize ||
      head[1] < 0 ||
      head[1] >= boardSize ||
      snake
        .slice(1)
        .some((segment) => segment[0] === head[0] && segment[1] === head[1])
    );
  };

  const resetGame = () => {
    setSnake(initialSnake);
    setFood(initialFood);
    setDirection("RIGHT");
    setGameOver(false);
    setScore(0); // Reset score
    setModalOpen(false); // Close modal
  };

  const handleButtonClick = (direction) => {
    if (!gameOver) {
      setDirection(direction);
    }
  };

  return (
    <div>
      <div>
        <h2>Grow your snake</h2>
      </div>
      <div>
        <div className="board">
          {Array.from({ length: boardSize }, (_, row) => (
            <div key={row} className="row">
              {Array.from({ length: boardSize }, (_, col) => {
                const isSnake = snake.some(
                  (segment) => segment[0] === row && segment[1] === col
                );
                const isFood = food[0] === row && food[1] === col;
                return (
                  <div
                    key={col}
                    className={`cell ${isSnake ? "snake" : ""} ${
                      isFood ? "food" : ""
                    }`}
                  ></div>
                );
              })}
            </div>
          ))}
        </div>
        <div>
          <div className="joystick-container">
            <button
              className="joystick-button"
              onClick={() => handleButtonClick("UP")}
            >
              Up
            </button>
            <div className="joystick-row">
              <button
                className="joystick-button"
                onClick={() => handleButtonClick("LEFT")}
              >
                Left
              </button>
              <button
                className="joystick-button"
                onClick={() => handleButtonClick("RIGHT")}
              >
                Right
              </button>
            </div>
            <button
              className="joystick-button"
              onClick={() => handleButtonClick("DOWN")}
            >
              Down
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        onClose={resetGame}
        score={score}
        image={Math.floor(Math.random() * (11 - 1 + 1))}
        scoreText={scoreTextConfig[Math.ceil(score / 10)] || scoreTextConfig[1]}
      />
    </div>
  );
};

export default App;
